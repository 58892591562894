<template>
  <section class="invoice-preview-wrapper">
    <div
      v-for="(piece, index) in data.pieces"
      id="container"
      :key="piece.id"
    >
      <b-row
        class="invoice-preview"
      >
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          md="12"
          xl="12"
        >
          <b-card
            class="invoice-preview-card"
            no-body
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <h2><b>{{ data.client.name }}</b></h2>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-0">
                  <div class="invoice-date-wrapper pt-2 pb-0">
                    <p class="invoice-date-title">
                      Invoice No.:
                    </p>
                    <p class="invoice-date">
                      #{{ data.awb }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper pb-0">
                    <p class="invoice-date-title">
                      Pieces:
                    </p>
                    <p class="invoice-date">
                      <b>{{ (index + 1) + "/" + data.pieces.length }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  class="p-0"
                  cols="12"
                  xl="6"
                >
                  <h3 class="mb-2">
                    Sender Information:
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <b>Name:</b>
                        </td>
                        <td>{{ data.client ? data.client.name : '' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Account:</b>
                        </td>
                        <td>{{ data.client.client_no }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Phone:</b>
                        </td>
                        <td>{{ data.client ? data.client.phone : '' }}, {{ data.client ? data.client.mobile : '' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>City:</b>
                        </td>
                        <td>{{ data.client.city.title }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Country:</b>
                        </td>
                        <td>{{ data.client.city.country.title }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Address:</b>
                        </td>
                        <td>{{ data.client_address ? data.client_address.address : '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                  cols="12"
                  xl="6"
                >
                  <div>
                    <h3 class="mb-2">
                      Consignee Information:
                    </h3>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            <b>Name:</b>
                          </td>
                          <td><span class="font-weight-bold">{{
                            data.consignee_name ? data.consignee_name : (data.consignee ? data.consignee.name : '')
                          }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Area:</b>
                          </td>
                          <td>{{ data.area }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>City:</b>
                          </td>
                          <td>{{ data.city ? data.city.title : '' }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Country:</b>
                          </td>
                          {{ data.city ? data.city.country.title : '' }}
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Address:</b>
                          </td>
                          <td>{{
                            data.address ? data.address : data.consignee ? data.consignee.addresses[0].name : ''
                          }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Phone:</b>
                          </td>
                          <td>{{ data.phone1 ? data.phone1 : data.consignee ? data.consignee.phone : '' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <table
              :key="piece.id"
              class="table table-responsive-sm"
            >
              <tr>
                <th>Description</th>
                <th>Origin</th>
                <th>Weight</th>
                <th>Price</th>
              </tr>
              <tr
                v-for="item in piece.items"
                :key="item.id"
              >
                <td>{{ item.description_of_goods }}</td>
                <td>{{ item.country_of_origin }}</td>
                <td>{{ item.weight }}</td>
                <td>{{ item.price }}</td>
              </tr>
            </table>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  class="mt-md-0 mt-3"
                  cols="12"
                  md="6"
                  order="2"
                  order-md="1"
                >
                  <!--                                <b-card-text class="mb-0">
                                                      <span class="font-weight-bold">Salesperson:</span>
                                                      <span class="ml-75">Alfie Solomons</span>
                                                  </b-card-text>-->
                </b-col>

                <!-- Col: Total -->
                <b-col
                  class="mt-md-6 d-flex justify-content-end"
                  cols="12"
                  md="6"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        {{ data.items_price }} $
                      </p>
                    </div>
                    <!--                                    <div class="invoice-total-item">
                                                            <p class="invoice-total-title">
                                                                Discount:
                                                            </p>
                                                            <p class="invoice-total-amount">
                                                                0
                                                            </p>
                                                        </div>-->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Fees:
                      </p>
                      <p class="invoice-total-amount">
                        {{ data.fees }} $
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ data.items_price + data.fees }} $
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold"><b>Terms & Condition:</b></span>
              <span>The above‐ mentioned value declared for customs purpose only. I/We hereby certify that the
                information on this invoice is true and correct and that the contents of this shipment are as stated above</span>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <div class="page-break" />
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  props: ['data'],
  data() {
    return {
      invoiceDescription: [{
        description_of_goods: this.data.description_of_goods,
        country_of_origin: this.data.country_of_origin,
        weight: this.data.weight,
        pieces: this.data.pieces,
      }],
      fields: ['description_of_goods', 'country_of_origin', 'weight', 'pieces'],
    }
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
#items_table th td {
  border: 1px solid black;
}

.width-17 {
  width: 17%;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
    height: 50% !important;
  }
  // Global Styles
  body {
    background-color: transparent !important;
    font-size: 18px;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }

    .invoice-preview {
      display: block;
      height: 100%;
    }
  }
}
</style>
